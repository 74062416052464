/**
 * 用户权限接口
 */
import { createAxios } from '@/utils/request'
import { IApplyParams } from '../types/workBench'

const request = createAxios()
const urlPrefix = process.env.VUE_APP_URL_API
const basePrefix = process.env.VUE_APP_URL_BASE
const urlPrefixIraq = process.env.VUE_APP_URL_PVOC_SEE

// 用户权限信息php // todo php接口需要用java的token调/permission接口，拿到响应中的token才能兼容php and java接口
export const permission = () => {
  return request({
    method: 'GET',
    url: `${urlPrefix}/permission`
  })
}
// // 用户权限信息 java
// export const permission = () => {
//   return request({
//     method: 'GET',
//     url: `${basePrefix}/user/auth`
//   })
// }

// 切换语言
export const switchLang = (data: any) => {
  return request<IApplyParams>({
    method: 'POST',
    url: `${urlPrefix}/switch-lang`,
    data
  })
}

// 跳转1.0获取token
export const getTokenCode = () => {
  return request({
    method: 'POST',
    url: `${urlPrefix}/auth/code`
  })
}

// 根据tokenCode获取token
export const getToken = (_tokenCode: any) => {
  return request({
    method: 'POST',
    url: `${urlPrefix}/quick/login`,
    data: {
      _tokenCode
    }
  })
}

// 获取用户信息
export const getInfo = () => {
  return request({
    method: 'GET',
    url: `${basePrefix}/user`
  })
}

// 获取验证码
export const getCode = () => {
  return request({
    method: 'GET',
    url: `${basePrefix}/login/captcha`
  })
}

// 登录
export const login = (data: any) => {
  return request<IApplyParams>({
    method: 'POST',
    url: `${basePrefix}/login`,
    data
  })
}

// 登出
export const logout = (data?: any) => {
  return request<IApplyParams>({
    method: 'POST',
    url: `${basePrefix}/login/out`,
    data: data || {}
  })
}

// 获取短信验证码
export const smsCode = (mobile: any) => {
  return request<IApplyParams>({
    method: 'GET',
    url: `${basePrefix}/login/sendSms/${mobile}`
  })
}

// 手机短信登录
export const smslogin = (data: any) => {
  return request<IApplyParams>({
    method: 'POST',
    url: `${basePrefix}/login/mobile`,
    data
  })
}

// 企业微信登录
export const weComlogin = (code: any) => {
  return request<IApplyParams>({
    method: 'GET',
    url: `${urlPrefixIraq}/login/weLogin/${code}`,
    params: {
      code
    }
  })
}
// 修改个人资料
export const editUserInfo = (data: any) => {
  return request<IApplyParams>({
    method: 'PUT',
    url: `${basePrefix}/user/${data.id}`,
    data
  })
}
