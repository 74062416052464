import sysConfig from '@/config'
import CryptoJS from 'crypto-js'

// data
export const storage = {
  getItem: <T>(key: string) => {
    const data = window.localStorage.getItem(key)
    if (!data) return null
    try {
      return JSON.parse(data) as T
    } catch (err) {
      return null
    }
  },
  getStringItem: <T>(key: string) => {
    const data = window.localStorage.getItem(key)
    if (!data) return null
    try {
      return data
    } catch (err) {
      return null
    }
  },
  setItem: (key: string, value: object | string | null) => {
    if (typeof value === 'object') {
      value = JSON.stringify(value)
    }
    window.localStorage.setItem(key, value)
  },
  removeItem: (key: string) => {
    window.localStorage.removeItem(key)
  }
}

/* cookie */
export const cookie = {
  set (name: any, value: any, config = {}) {
    const cfg = {
      expires: null,
      path: null,
      domain: null,
      secure: false,
      httpOnly: false,
      ...config
    }
    let cookieStr = `${name}=${escape(value)}`
    if (cfg.expires) {
      const exp = new Date()
      exp.setTime(exp.getTime() + parseInt(cfg.expires) * 1000)
      cookieStr += `;expires=${exp.toUTCString()}`
    }
    if (cfg.path) {
      cookieStr += `;path=${cfg.path}`
    }
    if (cfg.domain) {
      cookieStr += `;domain=${cfg.domain}`
    }
    document.cookie = cookieStr
  },
  get (name: any) {
    const arr = document.cookie.match(new RegExp('(^| )' + name + '=([^;]*)(;|$)'))
    if (arr != null) {
      return unescape(arr[2])
    } else {
      return null
    }
  },
  remove (name: any) {
    const exp = new Date()
    exp.setTime(exp.getTime() - 1)
    document.cookie = `${name}=;expires=${exp.toUTCString()}`
  }
}

export const crypot = {
  MD5 (data:string) {
    return CryptoJS.MD5(data).toString()
  },
  BASE64: {
    encrypt (data: any) {
      return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(data))
    },
    decrypt (cipher: any) {
      return CryptoJS.enc.Base64.parse(cipher).toString(CryptoJS.enc.Utf8)
    }
  },
  AES: {
    encrypt (data: string, secretKey: string) {
      if (secretKey.length % 8 !== 0) {
        console.warn('[WSTC error]: 秘钥长度需为8的倍数，否则解密将会失败。')
      } else {
        secretKey = sysConfig.LS_ENCRYPTION_key
      }
      data = JSON.stringify(data)
      const result = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(secretKey), {
        // iv: CryptoJS.enc.Utf8.parse(config.iv || ''),
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      })
      return result.ciphertext.toString()
    },
    decrypt (cipher: string, secretKey: string) {
      const cipher2 = CryptoJS.enc.Hex.parse(cipher)
      const data = CryptoJS.enc.Base64.stringify(cipher2)
      const result = CryptoJS.AES.decrypt(data, CryptoJS.enc.Utf8.parse(secretKey), {
        // iv: CryptoJS.enc.Utf8.parse(config.iv || ''),
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }).toString(CryptoJS.enc.Utf8)
      return JSON.parse(result)
    }
  }
}
