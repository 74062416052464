import { defineStore } from 'pinia'

export const useMainStore = defineStore('main', {
  state: () => ({
    PermissionMenuList: [], // 所有管理系统菜单权限列表
    userInfo: {}, // 账号信息
    activePage: '', // 当前菜单name
    currentMenu: {
      title: ''
    }, // 点击当前菜单信息
    currentApplyType: localStorage.getItem('APPLY_TYPE') ? Number(localStorage.getItem('APPLY_TYPE')) : null, // 当前系统全局申请类型id
    inspectApplyTypeList: [], // 所有验货申请类型 eg:肯尼亚、博兹瓦纳、伊拉克等
    currentApplyTypeData: {}, // 二级申请类型列表 eg:肯尼亚
    applicationItem: [], // 二级申请类型列表 eg:肯尼亚 todo 待页面去除后删除
    permissionsList: [], // 所有管理系统按钮权限列表
    routesList: [], // 路由列表
    rolesList: [], // 角色列表
    selectPVOCInfo: {}, // 创建申请单第一步选择的信息
    applicationFormExcelData: '', // 导入申请单excel获取数据
    quotationData: {}, // 报价单数据
    applyTypeList: { // 子业务类型数据
      COC: 1,
      SOR: 2,
      SOL: 3
    },
    dictionaryList: {} // 字典数据

  }),
  getters: {

  },
  actions: {
    updateCurrentMenu (val: any) {
      this.currentMenu = val
    },
    setQuotationData (val:any) {
      this.quotationData = val
    }
  }
})
