import sysConfig from '@/config'
import { createI18n } from 'vue-i18n'
import elZhCn from 'element-plus/dist/locale/zh-cn.mjs'
import elEn from 'element-plus/dist/locale/en.mjs'
import zh_CN from './lang/zh-cn'
import en from './lang/en'
import { storage } from '@/utils/tool'

const messages = {
  zh_CN: {
    el: elZhCn.el,
    ...zh_CN
  },
  en: {
    el: elEn.el,
    ...en
  }
}

const i18n = createI18n({
  locale: storage.getStringItem('LANG') || sysConfig.LANG,
  fallbackLocale: 'zh_CN',
  globalInjection: true,
  messages
})

export default i18n
