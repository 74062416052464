const DEFAULT_CONFIG = {
  // 接口地址
  // API_URL: process.env.NODE_ENV === 'development' ? '/api' : '/backend/v1',

  // 请求超时
  TIMEOUT: 10000,

  // 系统名称
  SYSTEM_NAME: '无线通讯VOC管理系统',

  SYSTEM_VER: '2.0.0',

  // 是否显示第三方授权登录
  MY_SHOW_LOGIN_OAUTH: true,

  // 状态为开启 接口需要
  STATUS_ON: 1,

  // 状态为关闭
  STATUS_OFF: -1,

  // TokenName
  TOKEN_NAME: 'Authorization',

  // Token前缀，注意最后有个空格，如不需要需设置空字符串
  TOKEN_PREFIX: 'Bearer ',

  // 语言
  LANG: 'zh_CN', // zh_CN / en

  // 主题颜色
  COLOR: '#00a8f5',

  // 是否加密localStorage, 为空不加密，可填写AES(模式ECB,移位Pkcs7)加密
  LS_ENCRYPTION: '',

  // localStorageAES加密秘钥，位数建议填写8的倍数
  LS_ENCRYPTION_key: 'kc2ETTq+yUbOchA='
}
export default DEFAULT_CONFIG
