
// 导入axios
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { ElMessage } from 'element-plus'
import router from '@/router'
import { cookie, storage } from '@/utils/tool'
import i18n from '@/locales'
import DEFAULT_CONFIG from '@/config'

// 处理  类型“AxiosResponse<any, any>”上不存在属性“code”。有其他未定义属性可以继续添加
declare module 'axios' {
  interface AxiosResponse{
    code: number;
    msg: string;
    result: any;
    resultSize: number;
  }
}

// 用于限制出现多次异常message
let responseCode : any = null
// 国际化
const { t } = i18n.global as any

// 请求配置
export const createAxios = (config?: AxiosRequestConfig): AxiosInstance => {
  const instance = axios.create({
    // baseURL: '',
    timeout: 600000000,
    headers: {
      'Access-Control-Allow-Origin-Type': '*',
      langId: localStorage.getItem('LANG') || 'zh_CN' // 默认中文
    },
    withCredentials: true, // 跨域携带cookie
    // transformResponse: [function (data) {
    //   try {
    //     return JSONBig.parse(data)
    //   } catch (err) {
    //     return data
    //   }
    // }],
    ...config // 自定义配置覆盖基本配置
  })

  // 添加请求拦截器
  instance.interceptors.request.use(function (config: any) {
    if (Object.prototype.toString.call(config.data) !== '[object FormData]') {
      config.headers['Content-Type'] = 'application/json;charset=UTF-8'
    }
    const token: any = storage.getStringItem('TOKEN_INSPECT') ? storage.getStringItem('TOKEN_INSPECT') : ''
    const token_php: any = storage.getStringItem('TOKEN') ? storage.getStringItem('TOKEN') : ''
    // const token = cookie.get('TOKEN_INSPECT') ? cookie.get('TOKEN_INSPECT') : cookie.get('TOKEN') ? cookie.get('TOKEN') : ''
    // console.log('config：', config)
    if (config.url.includes('permission') && !config.url.includes('permission/')) {
      if (token && !token_php) {
        config.headers.token = `all_java_${token}`
      } else {
        // 1.0跳转过来的token，调了permission接口后，会将php-token穿透一份在java
        config.headers[DEFAULT_CONFIG.TOKEN_NAME] = token_php
        localStorage.setItem('TOKEN_INSPECT', token_php)
      }
    } else {
      // if (config.url.includes('api') || config.url.includes('backend') || config.url.includes('v1') || config.url.includes('wcc') || config.url.includes('pvoc') || config.url.includes('online')) {
      //   console.log(config.url, '--php--', token_php)
      // } else {
      //   console.log(config.url, '--java--', token)
      // }
      const is_java = config.url.includes('api') || config.url.includes('backend') || config.url.includes('v1') || config.url.includes('wcc') || config.url.includes('pvoc') || config.url.includes('online')
      config.headers[DEFAULT_CONFIG.TOKEN_NAME] = is_java ? token_php : token // DEFAULT_CONFIG.TOKEN_PREFIX + token
    }

    // if (token) {
    //   config.headers[DEFAULT_CONFIG.TOKEN_NAME] = DEFAULT_CONFIG.TOKEN_PREFIX + token
    // }
    // Object.assign(config.headers, DEFAULT_CONFIG.HEADERS)
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
  )

  // 响应拦截器
  instance.interceptors.response.use(function (response) {
    if (response.headers && response.headers.token) {
      storage.setItem('TOKEN', `Bearer ${response.headers.token}`)
    }
    return response.data
  },
  function (error) {
    if (error && error.response) {
      // 1.公共错误处理
      // 2.根据响应码具体处理
      switch (error.response.status) {
        case 400:
          error.message = error.response.data.data[0]
          break
        case 401:
          storage.removeItem('TOKEN_INSPECT')
          storage.removeItem('TOKEN_CLOUD')
          storage.removeItem('TOKEN')
          // // 判断是否有qiankun
          // if ((window as any).__POWERED_BY_QIANKUN__) {
          //   window.location.href = `${window.location.origin}/login`
          // } else {
          //   router.push('/login')
          // }
          router.push('/login')
          error.message = t('request.401')
          // error.message = error.response.config && error.response.config.url ? t('request.401') + error.response.config.url : ''
          break
        case 403:
          error.message = t('request.403') // 拒绝访问
          break
        case 404:
          error.message = t('request.403') // 请求错误,未找到该资源
          break
        case 405:
          error.message = t('request.405') // 请求方法未允许
          break
        case 408:
          error.message = t('request.408') // 请求超时
          break
        case 500:
          error.message = t('request.500') // 服务器端出错
          break
        case 501:
          error.message = t('request.501') // 网络未实现
          break
        case 502:
          error.message = t('request.502') // 网络错误
          break
        case 503:
          error.message = t('request.503') // 服务不可用
          break
        case 504:
          error.message = t('request.504') // 网络超时
          break
        case 505:
          error.message = t('request.505') // http版本不支持该请求
          break
        default:
          error.message = `${t('request.linkError')}${error.response.status}`
      }
    } else {
      // 超时处理
      if (JSON.stringify(error).includes('timeout')) {
        error.message = t('request.timeout')
      } else {
        error.message = t('request.linkServerError')
      }
    }
    // 在1s内，出现同样的code码只显示一次
    if (!responseCode || (responseCode && responseCode !== error.response.status)) {
      ElMessage.error(error.message)
    }
    responseCode = error.response.status
    setTimeout(() => {
      responseCode = null
    }, 1000)
    return Promise.reject(error.response)
  }
  )

  return instance
}
