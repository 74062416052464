import './public-path'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import pinia from '@/stores/store'
import i18n from '@/locales'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/dark/css-vars.css'
import config from '@/config'
import { useMainStore } from '@/stores/main'

// 为保证子应用也可以独立运行，需做以下判断
// eslint-disable-next-line no-unused-expressions
let instance: any = null
function render (props?: any) {
  const container = props ? props.container : null
  instance = createApp(App)
  instance.use(pinia)
  instance.use(router)
  // size全局设置表单组件的默认尺寸，zIndex 用于设置弹出组件的层级
  instance.use(ElementPlus, {
    size: 'default',
    zIndex: 3000
  })
  instance.use(i18n)

  // 注册全局指令-按钮权限
  instance.directive('permissions', (el: { remove: () => void }, binding: { value: any }) => {
    const store = useMainStore()
    const permissionsInfo: any = store.permissionsList || []
    if (permissionsInfo.indexOf(binding.value) === -1) {
      el.remove()
    }
  })
  // 挂载全局参数
  instance.config.globalProperties.$config = config
  instance.config.globalProperties.$i18n = i18n.global
  instance.mount(container ? container.querySelector('#app') : '#app')
}
// 独立运行时
if (!(window as any).__POWERED_BY_QIANKUN__) { render() }
export async function bootstrap () {
  // console.log('VOC系统--[vue] vue app bootstraped')
}
export async function mount (props: any) {
  // console.log('VOC系统--[vue] props from main framework', props)
  render(props)
}
export async function unmount () {
  // instance.$destroy()
  // instance.$el.innerHTML = ''
  instance = null
}
router.onError((error) => {
  // 防IE缓存导致找不到js文件
  const pattern = /Loading chunk \d+ failed/g
  const isChunkLoadFailed = error.message.match(pattern)
  if (isChunkLoadFailed) {
    location.reload()
  }
})
router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g
  const isChunkLoadFailed = error.message.match(pattern)
  const targetPath = router.currentRoute.value.fullPath
  if (isChunkLoadFailed) {
    router.replace(targetPath)
  }
})
