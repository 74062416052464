import { createAxios } from '@/utils/request'
const request = createAxios()
const urlPrefix = process.env.VUE_APP_URL_API

// 消息列表
export const messageList = (data: any) => {
  return request({
    method: 'GET',
    url: `${urlPrefix}/corner/notify`,
    params: data
  })
}

// 消息详情
export const messageDetails = (data: any) => {
  return request({
    method: 'GET',
    url: `${urlPrefix}/corner/notify/${data}`
  })
}

// 消息全部已读
export const allRead = (data: any) => {
  return request({
    method: 'POST',
    url: `${urlPrefix}/corner/notify/deal`,
    data
  })
}

// 消息删除
export const messageDel = (data: any) => {
  return request({
    method: 'DELETE',
    url: `${urlPrefix}/corner/notify/${data}`
  })
}
